// JavaScript Document
$(document).ready(function(){
	$(".system").click(function(){
		$(this).hide("slow");
	});
	
	jQuery("a[rel^='prettyPhoto']").prettyPhoto({
			social_tools:'',
			slideshow:3000,
	});
	
	jQuery('#slider').nivoSlider({
		effect: 'fade', 
		animSpeed: 1500,
		pauseTime: 5000, // How long each slide will show
		startSlide: 0,
		directionNav: false,
		controlNav: false,
		controlNavThumbs: false
	});
	
	function initialize() {
		var point = new google.maps.LatLng(47.52433,19.060035);
		var center = new google.maps.LatLng(47.52433,19.060035);
		var mapOptions = {
		  panControl: false,
		  zoomControl: false,
		  scaleControl: false,
		  mapTypeControl: false,
		  center: center,
		  zoom: 16,
		  mapTypeId: google.maps.MapTypeId.ROADMAP
		};
		
		var image = 'http://www.greenthai.hu/component/images/marker.png';
		var map = new google.maps.Map(document.getElementById('map'), mapOptions);
		var marker = new google.maps.Marker({
		  map: map,
		  url: 'https://maps.google.com/maps?q=47.524482,19.059649&hl=hu&num=1&t=m&z=18',
		  position: point,
		  icon: image
		});
		
		google.maps.event.addListener(marker, 'click', function() {
			window.open([marker.url], "_blank");
		});
	  }
	  
	//Google maps
	initialize();

	///popUp info szöveg

    /*function setCookie(cname,cvalue,exdays) {
        var d = new Date();
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = "expires="+d.toGMTString();
        document.cookie = cname+"="+cvalue+"; "+expires;
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++){
            var c = ca[i].trim();
            if (c.indexOf(name)==0) 
                return c.substring(name.length,c.length);
        }
        return "";
    }
	var elrejt=getCookie("elrejt");
    if(elrejt != "true"){
    	$(".popup").click();
    	setCookie("elrejt",true,60);
    }*/
});
